import * as React from "react"
import { graphql, Link, StaticQuery, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"

import Helmet from "react-helmet"
import OverviewSection from "../components/overview/Overview"
import Layout from "../components/layout/Layout"
import HeroSlider from "../components/slider/HeroSlider"
import CounterBlock from "../components/countup/Countup"
import Videos from "../components/videos/Videos"
import Apartments from "../components/apartments/Apartments"
import Gallery from "../components/gallery/Gallery"
import Googlemap from "../components/map/Googlemap"
import Heading from "../components/common/heading/Heading"
import Breadcrumb from "../components/common/breadcrumb/breadcrumb"
// import { Breadcrumb } from "react-bootstrap"

const NosotrosPage = () => {
  return (
    <Layout>
      <div className="about-page-wrap">
        <Helmet>
          <title>Mahpsa | Nosotros</title>
        </Helmet>
        <Breadcrumb title={"Nosotros"} />
        <div className="section-wrapper mb-md-2">
          <div className="container">
            <div
              style={{ margin: 0 }}
              className="row align-items-center justify-content-cent"
            >
              <div className="col-sm-12 col-md-6 content-left ">
                <Heading>¿QUIÉNES SOMOS?</Heading>
                <p
                  className="text-black text-justify"
                  style={{ textAlign: "justify" }}
                >
                  MAHPSA S.A.C fue creado como una división especializada dentro
                  del Grupo Conservi para operar en bienes raíces, consultoría
                  de negocios, así como asesoramiento en las áreas técnicas,
                  económicas y financieras de los proyectos de construcción.
                </p>
                <p className="text-justify" style={{ textAlign: "justify" }}>
                  Nuestra empresa forma parte del Grupo Conservi, empresa
                  promotora y constructora con más de 25 años de experiencia en
                  el sector construcción e inmobiliario.En el año 2012 hemos
                  incursionado en el sector hotelero, con la construcción e
                  implementación de uno de los mejores hoteles resorts en el
                  Norte del país en una alianza estratégica con Casa Andina.En
                  el 2018 hemos incursionado en el sector retail, con la
                  construcción e implementación de Qhatu Plaza, Mall Urbano en
                  Ate.Y estamos en constante evolución, reinventándonos para
                  brindar el mejor servicio a nuestros clientes.
                </p>
              </div>
              <div className="col-sm-12 col-md-6 ">
                {/* <img className={'w-100'} src={groupLogo} alt="" /> */}
                <StaticImage
                  src="../assets/images/aboutus/group-logo.png"
                  alt={""}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="section-wrapper p-0 mt-4">
          <div className="container">
            <div className="text-description text-center">
              <h4>
                <i>"Nuestra misión es brindar servicios y productos de</i>
              </h4>
              <h4>
                <i>alta calidad otorgando eficiencia y profesionalismo</i>
              </h4>
              <h4>
                <i>para satisfacción de nuestros clientes"</i>
              </h4>
            </div>
          </div>
        </div>
        <div className="section-wrapper">
          <div className="ourteam">
            <div className="container mb-1 mt-5">
              <Heading>EMPRESAS DEL GRUPO</Heading>
              {/* <div className={'row  pb-4 mb-4'}>
                                <div className="d-flex justify-content-center">
                                    <h3 className={'align-self-center'}><u>Empresas del grupo</u></h3>
                                </div>
                            </div> */}
              <div className="d-flex justify-content-around pt-3">
                <StaticImage
                  src="../assets/images/aboutus/conservi-logo.png"
                  alt="Conservi Group logo"
                  height={150}
                />
                <StaticImage
                  src="../assets/images/aboutus/mahpsa-logo.png"
                  alt="Mahpsa Group logo"
                  height={150}
                />
                <StaticImage
                  layout="constrained"
                  src="../assets/images/aboutus/terrazas-logo.png"
                  alt="Terrazas logo"
                  height={150}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="section-wrapper">
          <div className="container mb-3 pt-3">
            <Heading>GALERIA</Heading>
            <div className={"row justify-content-between"}>
              <div className={"col-sm-12 col-md-6 p-5"}>
                {/* <p className={"mb-0"} style={{ color: "#515151" }}>
                    Oficinas “Centro Empresarial Premium”- Miraflores
                  </p>
                  <StaticImage
                    className="w-100 d-block"
                    src="../assets/images/aboutus/premium.jpg"
                    alt="Premium image"
                  /> */}
                <div className="property overflow-hidden">
                  <div className="property-wrap projects">
                    <div className="post-thumbnail">
                      <StaticImage
                        className="w-100 d-block"
                        src="../assets/images/aboutus/premium.jpg"
                        alt="Premium image"
                      />
                    </div>
                    <div className="entry-content text-center text-uppercase item p-0">
                      <div className="">
                        <h6 className="text-white">
                          Oficinas “Centro Empresarial Premium”
                        </h6>
                        <h5 className="text-white">Miraflores</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={"col-sm-12 col-md-6 p-5"}>
                {/* <p className={"mb-0"} style={{ color: "#515151" }}>
                  Residencial “Boulevard de Chorrillos” - Chorrillos
                </p>
                <StaticImage
                  className="w-100 d-block"
                  src="../assets/images/aboutus/chorrillos.jpg"
                  alt="Chorrillos building image"
                /> */}
                <div className="property overflow-hidden">
                  <div className="property-wrap projects">
                    <div className="post-thumbnail">
                      <StaticImage
                        className="w-100 d-block"
                        src="../assets/images/aboutus/chorrillos.jpg"
                        alt="Chorrillos building image"
                      />
                    </div>
                    <div className="entry-content text-center text-uppercase item p-0">
                      <div className="">
                        <h6 className="text-white">
                          Residencial “Boulevard de Chorrillos”
                        </h6>
                        <h5 className="text-white">Chorrillos</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={"row justify-content-around"}>
              <div className={"col-sm-12 col-md-6 p-5"}>
                {/* <p className={"mb-0"} style={{ color: "#515151" }}>
                  Residencial “Doña Hilda” – Los Rosales, Santiago de Surco
                </p>
                <StaticImage
                  className="w-100 d-block"
                  src="../assets/images/aboutus/hilda.jpg"
                  alt="Dona Hilda building image"
                /> */}
                <div className="property overflow-hidden">
                  <div className="property-wrap projects">
                    <div className="post-thumbnail">
                      <StaticImage
                        className="w-100 d-block"
                        src="../assets/images/aboutus/hilda.jpg"
                        alt="Dona Hilda building image"
                      />
                    </div>
                    <div className="entry-content text-center text-uppercase item p-0">
                      <div className="">
                        <h6 className="text-white">
                          Residencial “Doña Hilda” – Los Rosales
                        </h6>
                        <h5 className="text-white">Surco</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={"col-sm-12 col-md-6 p-5"}>
                {/* <p className={"mb-0"} style={{ color: "#515151" }}>
                  Residencial “Alcázar de la Castellana” – La Castellana,
                  Santiago de Surco
                </p>
                <StaticImage
                  className="w-100 d-block"
                  src="../assets/images/aboutus/alcazar.jpg"
                  alt="Alcazar building image"
                /> */}
                <div className="property overflow-hidden">
                  <div className="property-wrap projects">
                    <div className="post-thumbnail">
                      <StaticImage
                        className="w-100 d-block"
                        src="../assets/images/aboutus/alcazar.jpg"
                        alt="Alcazar building image"
                      />
                    </div>
                    <div className="entry-content text-center text-uppercase item p-0">
                      <div className="">
                        <h6 className="text-white">
                          Residencial “Alcázar” - La Castellana
                        </h6>
                        <h5 className="text-white">Surco</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-5">
            <div className={"row justify-content-between"}>
              <div className={"col-sm-12 col-md-6 p-5"}>
                <StaticImage
                  className={"w-100"}
                  src="../assets/images/aboutus/bocapan1.jpg"
                  alt="Bocapan 1"
                />
              </div>
              <div className={"col-sm-12 col-md-6 p-5"}>
                {/* <img src={bocapan2} className={'w-100'} alt="" /> */}
                <StaticImage
                  className={"w-100"}
                  src="../assets/images/aboutus/bocapan2.jpg"
                  alt="Bocapan 2"
                />
              </div>
            </div>
            <div className={"row justify-content-around"}>
              <div className={"col-sm-12 col-md-6 p-5"}>
                {/* <img src={bocapan3} className={'w-100'} alt="" /> */}
                <StaticImage
                  className={"w-100"}
                  src="../assets/images/aboutus/bocapan3.jpg"
                  alt="Bocapan 3"
                />
              </div>
            </div>
          </div>

          <div className="container mt-5">
            {/* <div className="d-flex p-0 m-0 justify-content-center">
              <StaticImage
                src="../assets/images/aboutus/qhatu-logo.png"
                alt="Qhatu logo"
                height={150}
              />
            </div> */}
            <div className={"row justify-content-around"}>
              <div className={"col-sm-12 col-md-6 p-5"}>
                {/* <img src={qhatu} className={'w-100'} alt="" /> */}
                <StaticImage
                  className={"w-100"}
                  src="../assets/images/aboutus/qhatu.jpg"
                  alt="Qhatu mall"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NosotrosPage
